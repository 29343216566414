<template>
  <div :class="platform" class="deposit-bonus" data-t="deposit-bonus-banner">
    <div class="content">
      <div class="left-side">
        <div class="bonus-title">
          <h4 :title="bannerTitle">{{ bannerTitle }}</h4>
          <img
            v-if="platform !== 'mobile'"
            class="tooltip"
            src="./assets/tooltip.svg"
            @click="openTooltip"
          />
        </div>
        <DepositBonusAwards
          size="m"
          direction="column"
          :awards="bonusesOutput"
          :is-step-bonus="isStepBonus"
        />
      </div>
      <div class="right-side">
        <div class="min-deposit-title">{{ t('bonuses.depositMinShort') }}</div>
        <div class="min-deposit-amount">
          {{ minDeposit }}
          <span>
            ( ~{{ cryptoMinAmount }}
            <StIcon :name="selectedCurrencyIcon" size="16" />)
          </span>
        </div>
        <div class="bonus-expiration">
          <span v-if="platform === 'desktop'">
            {{ t('bonuses.bonusExpires') }}
          </span>
          <BonusTimer
            v-if="bonus.expiredAt"
            size="s"
            :is-mobile="platform === 'mobile'"
            :expires-at="bonus.expiredAt"
          />
        </div>
        <div v-if="platform === 'mobile'" class="mobile-tooltip">
          <img
            class="tooltip"
            src="./assets/tooltip.svg"
            @click="openTooltip"
          />
        </div>

        <div class="chest">
          <img
            v-if="platform === 'desktop'"
            width="107"
            height="107"
            src="./assets/chest.png"
          />
          <img v-else width="59" height="59" src="./assets/chest.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useConverterStore } from '@st/payments/stores/useConverterStore'
import { useCurrenciesStore } from '@st/payments/stores/useCurrenciesStore'
import BonusTimer from '../FreeBonusCard/parts/BonusTimer.vue'
import type { DepositBonus } from '../../types'
import { useDepositBonus } from '../../composables/useDepositBonus'
import DepositBonusAwards from '../DepositBonusAwards/DepositBonusAwards.vue'

const props = withDefaults(
  defineProps<{
    bonus: DepositBonus
    selectedCurrencyCode: string
    selectedCurrencyIcon: string
    platform: 'desktop' | 'mobile'
    type: 'deposit' | 'buy'
  }>(),
  {
    type: 'deposit',
  },
)

const { t } = useI18n()

const { bonus } = toRefs(props)

const { appCurrency } = storeToRefs(useCurrenciesStore())
const { convert } = useConverterStore()

const { format: formatCrypto } = useCryptoFormatter()

const cryptoMinAmount = computed(() =>
  formatCrypto(
    convert(props.bonus.minDepositAmountInAppCurrency, {
      from: appCurrency.value.code,
      to: props.selectedCurrencyCode,
    }),
  ),
)

const {
  minDepositAmount: minDeposit,
  titleType,
  bonusesOutput,
  isStepBonus,
} = useDepositBonus(bonus)

const bannerTitle = computed(() =>
  t('bonuses.depositNTitle', { type: titleType.value }),
)

const router = useRouter()
function openTooltip() {
  router.push({
    query: {
      modal: 'depositBonusTooltip',
      id: props.bonus.id,
    },
  })
}
</script>

<style scoped>
.deposit-bonus {
  display: flex;

  min-height: 104px;

  background: radial-gradient(
      102.33% 102.33% at 50% 19.65%,
      rgb(253 46 0 / 70%) 0%,
      rgb(232 0 42 / 7%) 85.92%
    ),
    #20202a;
  border-radius: var(--border-radius-150, 12px);
  box-shadow: 0 4px 40px 0 rgb(252 57 104 / 40%) inset;
}

.content {
  display: flex;
  width: 100%;
  padding: var(--spacing-150) var(--spacing-200) var(--spacing-150)
    var(--spacing-200);
}

.left-side {
  width: 206px;
  padding-right: var(--spacing-200);
}

.tooltip {
  cursor: pointer;
}

.right-side {
  position: relative;

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  padding-left: var(--spacing-250);

  border-left: 1px solid rgb(255 255 255 / 8%);
}

.bonus-title {
  display: flex;
  gap: var(--spacing-075);
  align-items: center;
  font: var(--desktop-text-md-semibold);

  h4 {
    overflow: hidden;

    max-width: 175px;
    margin: 0;

    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.min-deposit-title {
  width: fit-content;
  font: var(--desktop-text-xs-medium);
}

.min-deposit-amount {
  display: flex;
  gap: var(--spacing-075);
  align-items: baseline;
  font: var(--desktop-text-xl-semibold);

  span {
    display: flex;
    gap: var(--spacing-050);
    align-items: center;

    font: var(--desktop-text-md-medium);
    line-height: 0;
    color: var(--text-secondary);
  }
}

.bonus-expiration {
  display: flex;
  gap: var(--spacing-075);
  align-items: center;

  width: fit-content;
  margin-top: auto;
  padding: var(--spacing-050) var(--spacing-050) var(--spacing-050)
    var(--spacing-100);

  font: var(--desktop-text-xs-medium);
  color: var(--text-secondary, rgb(255 255 255 / 64%));

  background: rgb(255 255 255 / 8%);
  border-radius: var(--border-radius-075, 6px);
}

.chest {
  position: absolute;
  right: -30px;
  bottom: -30px;
}

.mobile.deposit-bonus {
  width: auto;
  min-height: 84px;

  .content {
    padding: var(--spacing-125) var(--spacing-125) var(--spacing-125)
      var(--spacing-150);
  }

  .left-side {
    width: 142px;
    padding-right: var(--spacing-150);
  }

  .right-side {
    padding-left: var(--spacing-150);
  }

  .bonus-title {
    font: var(--mobile-caption-1-medium);
  }

  .min-deposit-title {
    font: var(--mobile-caption-1-medium);
  }

  .min-deposit-amount {
    font: var(--mobile-headline-semibold);

    span {
      font: var(--mobile-caption-1-regular);
    }
  }

  .chest {
    right: -20px;
    bottom: -23px;
  }

  .mobile-tooltip {
    position: absolute;
    top: 0;
    right: 0;
  }

  .bonus-expiration {
    padding: var(--spacing-025) var(--spacing-075);
    font: var(--desktop-text-xs-medium);
  }

  .sub-bonus-text {
    overflow: hidden;
    max-width: 80px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
