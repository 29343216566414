<template>
  <form class="first-step" :class="platform" @submit.prevent="handleSubmit">
    <NavigationTabs :type="platform" />
    <div v-if="isVerification" class="prepare-verification">
      <StSpinner class="spinner" size="32" />
      <div class="text-content">
        <h2>{{ t('payments.deposit.verificationTitle') }}</h2>
        <span>{{ t('payments.deposit.verificationSubtitle') }}</span>
      </div>
    </div>
    <div v-else class="content">
      <div class="content-top">
        <h2 v-if="platform === 'desktop'">
          {{ t('payments.buyCrypto.title') }}
        </h2>
        <div class="top-info">
          <div class="info-block">
            <div class="image">
              <img src="../assets/balance.png" width="30" />
            </div>
            <div class="text">
              {{
                platform === 'desktop'
                  ? t('payments.buyCrypto.topInfo1')
                  : t('payments.buyCrypto.mTopInfo1')
              }}
            </div>
          </div>
          <div class="info-block">
            <div class="image">
              <img src="../assets/fee.png" width="30" />
            </div>
            <div class="text">{{ t('payments.buyCrypto.topInfo2') }}</div>
          </div>
          <div class="info-block">
            <div class="image">
              <img src="../assets/popular.png" width="30" />
            </div>
            <div class="text">
              {{ t('payments.buyCrypto.topInfo3') }}
            </div>
          </div>
        </div>
      </div>
      <div class="selects-wrapper">
        <DepositBonusBanner
          v-if="favoriteNewDepositBonus"
          :bonus="favoriteNewDepositBonus"
          :platform="platform"
          :selected-currency-code="selectedCurrency?.code"
          :selected-currency-icon="selectedCurrency?.icon"
          type="buy"
        />
        <div class="amount-wrapper">
          <InputWithSelect
            v-model:select="fiatCurrencyId"
            v-model:input="fiatAmount"
            :error="fiatAmountError"
            :input-placeholder="t('buyCrypto.enterAmount')"
            :platform="platform"
            :select-items="mappedGeoList"
            :title="t('payments.buyCrypto.inputTitle1')"
            @handle-focus="handleFiatAmountFocus"
            @update:input="onDecimalInputUpdate('fiat')"
          />
          <InputWithSelect
            v-model:select="currencyId"
            v-model:input="amount"
            :error="amountError"
            :input-placeholder="t('buyCrypto.enterAmount')"
            :platform="platform"
            :select-items="cryptoCurrenciesList"
            :title="t('payments.buyCrypto.inputTitle2')"
            :subtitle="convertRateText"
            is-disabled
            class="amount-input-select"
            @update:input="onDecimalInputUpdate('crypto')"
            @handle-focus="handleAmountFocus"
          />
          <!-- <FastAmounts
            :currency-id="currencyId"
            :network-id="networkId"
            :selected-currency-code="selectedCurrency.code"
            @handle-amount-click="handleAmountClick"
          /> -->
        </div>
      </div>
      <PaymentMethods
        :is-disabled="!fiatAmount"
        :platform="platform"
        :methods="methods"
        :selected-method="selectedMethod?.fixedRateId"
        :amount="fiatAmount"
        :selected-currency="currencyId"
        @payment-method-update="handleMethodUpdate"
      />
      <div class="disclaimer">
        {{ t('payments.buyCrypto.disclaimer') }}
      </div>

      <div class="button-wrapper">
        <StButton
          :size="platform === 'desktop' ? 'xl' : 'l'"
          :label="
            amount
              ? t('payments.buyCrypto.buy2', { amount: recieveAmount })
              : t('payments.buyCrypto.buy')
          "
          class="buy-button"
          :loading="isLoading"
          :disabled="isDisabledButton"
          submit
        />
        <div class="buy-crypto-tooltip-wrapper">
          <BuyCryptoTooltip />
        </div>
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import Decimal from '@st/decimal'
// eslint-disable-next-line import/no-extraneous-dependencies
import DepositBonusBanner from '@st/bonuses/components/DepositBonusBanner/DepositBonusBanner.vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDepositBonusesStore } from '@st/bonuses/stores/useDepositBonusesStore'
import { isValidNumber, minBy, sortBy } from '@st/utils'
import type { IconName } from '@st/ui/components/StIcon/types'
import { useCurrenciesStore } from '../../../stores/useCurrenciesStore'
import { useCalypso } from '../../../composables/useCalypso'
import InputWithSelect from '../../InputWithSelect/InputWithSelect.vue'
import { useAccountsStore } from '../../../stores/useAccountsStore'
import PaymentMethods from './PaymentMethods.vue'
import BuyCryptoTooltip from '../../BuyCryptoTooltip/BuyCryptoTooltip.vue'
// import FastAmounts from './FastAmounts.vue'
import { useLimits } from './useLimits'

const emit = defineEmits<{
  (e: 'goToNextStep', value: { frameUrl: string }): void
}>()

const props = withDefaults(
  defineProps<{
    platform: 'mobile' | 'desktop'
  }>(),
  { platform: 'desktop' },
)

const { t } = useI18n()
const stFetch = useRawStFetch()

const currenciesStore = useCurrenciesStore()
const accountsStore = useAccountsStore()
const { currencies, appCurrency } = storeToRefs(currenciesStore)
const { realAccounts } = storeToRefs(accountsStore)

const { allowedCurrencyIds } = useCalypso()

const SELECTED_CURRENCY_ID = 1
const SELECTED_NETWORK_ID = 4

const currencyId = ref(SELECTED_CURRENCY_ID)
const amount = ref('')

const currentInputFocus = ref<'crypto' | 'fiat'>()

function handleAmountFocus() {
  currentInputFocus.value = 'crypto'
}

function handleFiatAmountFocus() {
  currentInputFocus.value = 'fiat'
}

const networkId = computed(() => {
  const currency = currencies.value[currencyId.value]
  const filteredCurrency = currency.networks.filter(
    (network) => network.allowedForCalypso,
  )

  return filteredCurrency[0]?.id || SELECTED_NETWORK_ID
})

const selectedCurrency = computed(() =>
  currenciesStore.getCurrencyById(currencyId.value),
)

const { format: formatCrypto } = useCryptoFormatter({
  currency: computed(() => selectedCurrency.value.code),
})

const { format: formatCurrency } = useCurrencyFormatter({
  currency: appCurrency.value.code,
})

const cryptoCurrenciesList = computed(() =>
  realAccounts.value
    .filter((account) => allowedCurrencyIds.value.includes(account.currencyId))
    .map((account) => ({
      id: account.currencyId,
      title: account.code,
      value:
        props.platform === 'mobile' ? formatCrypto(account.balance) : undefined,
      subValue:
        props.platform === 'mobile'
          ? `~${formatCurrency(account.fiatBalance)}`
          : undefined,
      icon: account.icon,
    })),
)

const fiatAmount = ref('')

const { data: calypsoGeoData, execute: fetchDepositCalypsoGeo } = useStFetch(
  '/deposit/calypso/geo',
  {
    method: 'post',
    body: computed(() => ({
      currencyId: currencyId.value,
      networkId: networkId.value,
    })),
    immediate: false,
    watch: false,
  },
)

watchEffect(() => {
  if (!currencyId.value || !networkId.value) return
  fetchDepositCalypsoGeo()
})

const fiatCurrencyId = ref()
const geoList = computed(() => {
  if (!calypsoGeoData.value?.geo) return []

  return sortBy(calypsoGeoData.value?.geo, (item) => [item.geoCode])
})
const calypsoMethodId = computed(() => calypsoGeoData.value?.methodId)

const iconsMap: Record<string, IconName> = {
  PEN: 'f-per',
  BRL: 'f-bra',
  COP: 'f-col',
  PHP: 'f-phl',
  ZAR: 'f-zaf',
  KRW: 'f-kor',
  CLP: 'f-chl',
  MXN: 'f-mex',
  HKD: 'f-hkg',
  GHS: 'f-gha',
  THB: 'f-tha',
  NGN: 'f-nga',
  AZN: 'f-aze',
  KES: 'f-ken',
  NZD: 'f-nzl',
  MYR: 'f-mys',
  USD_ECU: 'f-ecu',
  VES: 'f-ven',
  IDR: 'f-idn',
  SGD: 'f-sgp',
  EUR: 'f-eu',
  AWG: 'f-abw',
  AFN: 'f-afg',
  AOA: 'f-ago',
  XCD: 'f-aia',
  ALL: 'f-alb',
  AED: 'f-are',
  ARS: 'f-arg',
  AMD: 'f-arm',
  AUD: 'f-aus',
  BIF: 'f-bdi',
  XOF: 'f-ben',
  BDT: 'f-bgd',
  BGN: 'f-bgr',
  BHD: 'f-bhr',
  BSD: 'f-bhs',
  BAM: 'f-bih',
  BYR: 'f-blr',
  BZD: 'f-blz',
  BMD: 'f-bmu',
  BOB: 'f-bol',
  BBD: 'f-brb',
  BND: 'f-brn',
  BTN: 'f-btn',
  BWP: 'f-bwa',
  XAF: 'f-caf',
  CHE: 'f-che',
  CNY: 'f-chn',
  CDF: 'f-cod',
  KMF: 'f-com',
  CVE: 'f-cpv',
  CRC: 'f-cri',
  CUP: 'f-cub',
  ANG: 'f-cuw',
  KYD: 'f-cym',
  CZK: 'f-cze',
  DJF: 'f-dji',
  DKK: 'f-dnk',
  DOP: 'f-dom',
  DZD: 'f-dza',
  EGP: 'f-egy',
  ERN: 'f-eri',
  ETB: 'f-eth',
  FJD: 'f-fji',
  FKP: 'f-flk',
  GBP: 'f-gbr',
  GEL: 'f-geo',
  GIP: 'f-gib',
  GNF: 'f-gin',
  GMD: 'f-gmb',
  GTQ: 'f-gtm',
  GYD: 'f-guy',
  HNL: 'f-hnd',
  HRK: 'f-hrv',
  HTG: 'f-hti',
  HUF: 'f-hun',
  INR: 'f-ind',
  IRR: 'f-irn',
  IQD: 'f-irq',
  ISK: 'f-isl',
  ILS: 'f-isr',
  JMD: 'f-jam',
  JPB: 'f-jey',
  JOD: 'f-jor',
  JPY: 'f-jpn',
  KZT: 'f-kaz',
  KGS: 'f-kgz',
  KHM: 'f-khm',
  KWD: 'f-kwt',
  LAK: 'f-lao',
  LBP: 'f-lbn',
  LRD: 'f-lbr',
  LYD: 'f-lby',
  CHF: 'f-lie',
  LKR: 'f-lka',
  LSL: 'f-lso',
  MOP: 'f-mac',
  MAD: 'f-mar',
  MDL: 'f-mda',
  MGA: 'f-mdg',
  MVR: 'f-mdv',
  MKD: 'f-mkd',
  MMK: 'f-mmr',
  MNT: 'f-mng',
  MZN: 'f-moz',
  MRU: 'f-mrt',
  MUR: 'f-mus',
  MWK: 'f-mwi',
  NAD: 'f-nam',
  NIO: 'f-nic',
  NOK: 'f-nor',
  NRP: 'f-npl',
  OMR: 'f-omn',
  PKR: 'f-pak',
  PAB: 'f-pan',
  PGK: 'f-png',
  PLN: 'f-pol',
  KPW: 'f-prk',
  PYG: 'f-pry',
  XPF: 'f-pyf',
  QAR: 'f-qat',
  RON: 'f-rou',
  RUB: 'f-rus',
  RWF: 'f-rwa',
  SAR: 'f-sau',
  SDG: 'f-sdn',
  SBD: 'f-slb',
  SLL: 'f-sle',
  SVC: 'f-slv',
  SOS: 'f-som',
  RSD: 'f-srb',
  SSP: 'f-ssd',
  STN: 'f-stp',
  SRD: 'f-sur',
  SEK: 'f-swe',
  SZL: 'f-swz',
  SCR: 'f-syc',
  SYP: 'f-syr',
  TJS: 'f-tjk',
  TMT: 'f-tkm',
  TOP: 'f-ton',
  TTD: 'f-tto',
  TND: 'f-tun',
  TRY: 'f-tur',
  TWD: 'f-twn',
  TZS: 'f-tza',
  UGX: 'f-uga',
  UAH: 'f-ukr',
  UYI: 'f-ury',
  USD: 'f-usa',
  UZS: 'f-uzb',
  VND: 'f-vnm',
  VUV: 'f-vut',
  WST: 'f-wsm',
  YER: 'f-yem',
  ZMW: 'f-zmb',
  ZWL: 'f-zwe',
}

const mappedGeoList = computed(
  () =>
    geoList.value?.map((item) => ({
      id: item.geoId,
      title: String(item.geoCode),
      icon: iconsMap[item.geoCode] ?? '',
    })),
)

watch(fiatCurrencyId, () => {
  amount.value = ''
})

watch(geoList, (newValue) => {
  if (!newValue.length) return

  fiatCurrencyId.value = newValue[0]?.geoId
})

const { data: calypsoRateData } = useStFetch('/deposit/calypso/rate', {
  method: 'post',
  body: computed(() => ({
    currencyId: currencyId.value,
    geoId: fiatCurrencyId.value,
    methodId: calypsoMethodId.value as string,
  })),
  immediate: false,
})

const methods = computed(() => calypsoRateData.value?.method || [])
const selectedMethod = ref()

watch(methods, (newValue) => {
  if (!newValue) return

  const { 0: firstElement } = newValue
  selectedMethod.value = firstElement
})

function handleMethodUpdate(methodId: string) {
  if (!methods.value) return

  selectedMethod.value = methods.value?.find(
    (method) => method.fixedRateId === methodId,
  )
}

const selectedMethodName = computed(() => {
  const foundMethod = methods.value.find(
    (method) => method.fixedRateId === selectedMethod.value?.fixedRateId,
  )
  if (!foundMethod) return ''

  return foundMethod.methodName
})

const convertRate = computed(() => selectedMethod.value?.fixedRate || 1)
const minimalConvertRate = computed(() => {
  const foundMethod = minBy(methods.value, (method) => method.fixedRate)

  return foundMethod?.fixedRate
})

const selectedFiatCurrency = computed(() =>
  mappedGeoList.value.find((currency) => currency.id === fiatCurrencyId.value),
)

const convertRateText = computed(() => {
  if (!convertRate.value || !minimalConvertRate.value) return ''

  const rate = new Decimal(1)
    .mul(minimalConvertRate.value)
    .toDecimalPlaces(2)
    .toString()

  return `${formatCrypto(1)} ≈ ${rate} ${
    selectedFiatCurrency.value?.title ?? ''
  }`
})

function onDecimalInputUpdate(type: 'crypto' | 'fiat') {
  if (type === 'crypto') {
    amount.value = amount.value.trim().replace(',', '.')
  }

  if (type === 'fiat') {
    fiatAmount.value = fiatAmount.value.trim().replace(',', '.')
  }
}

const isLoading = ref(false)
const amountError = ref('')
const fiatAmountError = ref('')

function calculateAmount() {
  if (!isValidNumber(fiatAmount.value)) {
    fiatAmountError.value = t('payments.withdrawal.validationError')
    return
  }

  amount.value = new Decimal(fiatAmount.value)
    .div(convertRate.value)
    .toDecimalPlaces(8)
    .toString()

  onDecimalInputUpdate('crypto')
}

function calculateFiatAmount() {
  if (!isValidNumber(amount.value)) {
    amountError.value = t('payments.withdrawal.validationError')
    return
  }

  fiatAmount.value = new Decimal(amount.value)
    .mul(convertRate.value)
    .toDecimalPlaces(8)
    .toString()
}

watch(amount, (newValue) => {
  amountError.value = ''
  fiatAmountError.value = ''

  if (!convertRate.value) return

  if (!newValue) {
    fiatAmount.value = ''

    return
  }

  if (currentInputFocus.value === 'crypto') {
    calculateFiatAmount()
  }
})

watch(fiatAmount, (newValue) => {
  amountError.value = ''
  fiatAmountError.value = ''

  if (!convertRate.value) return

  if (!newValue) {
    amount.value = ''

    return
  }

  if (currentInputFocus.value === 'fiat') {
    calculateAmount()
  }
})

watch(selectedMethod, (_, prevValue) => {
  if (!prevValue || (!amount.value && !fiatAmount.value)) return

  calculateAmount()
})

const recieveAmount = computed(() => {
  if (!isValidNumber(amount.value)) return ''

  return formatCrypto(amount.value)
})

const isDisabledButton = computed(() => {
  if (!isValidNumber(amount.value)) return true

  if (fiatAmountError.value || amountError.value) return true

  return !amount.value
})

// function handleAmountClick(amountValue: string) {
//   amount.value = amountValue

//   calculateFiatAmount()
// }

const limits = useLimits({
  selectedMethod,
  currencyId,
})

function validate(): boolean {
  if (
    !amount.value ||
    new Decimal(amount.value).greaterThan(limits.value.maxLimit)
  ) {
    amountError.value = t('payments.buyCrypto.maxAmountError', {
      maxAmount: formatCrypto(limits.value.maxLimit),
    })

    return false
  }

  if (new Decimal(amount.value).lessThan(limits.value.minLimit)) {
    amountError.value = t('payments.buyCrypto.minAmountError', {
      minAmount: formatCrypto(limits.value.minLimit),
    })
    return false
  }

  return true
}

const toast = useToast()
const isVerification = ref(false)

async function handleSubmit() {
  const isValid = validate()
  if (!isValid) return

  isLoading.value = true

  const { data, error } = await stFetch('/deposit/calypso/init', {
    method: 'post',
    body: {
      amount: amount.value,
      currencyId: currencyId.value,
      networkId: networkId.value,
      rateId: selectedMethod.value.fixedRateId,
      // @ts-expect-error not updated
      fiatCurrency: selectedFiatCurrency.value?.title,
      fiatAmount: fiatAmount.value,
      fiatMethod: selectedMethodName.value,
    },
  })
  isLoading.value = false

  if (error) {
    if (error.error === 'USER_VERIFICATION_CREATED') {
      isVerification.value = true

      return
    }

    toast.open({
      label: t('payments.buyCrypto.somethingWentWrong'),
      type: 'negative',
    })
    return
  }

  emit('goToNextStep', { frameUrl: data.calypsoDepositLink })
}

const io = useSocket()
const router = useRouter()

function handleRedirectToVerification(
  verificationStatus:
    | 'userWait'
    | 'adminWait'
    | 'adminInProgress'
    | 'approved'
    | 'rejected'
    | 'canceled'
    | 'failed',
) {
  if (verificationStatus === 'rejected' || verificationStatus === 'userWait') {
    router.replace({
      query: {
        modal: 'paymentsKyc',
        type: verificationStatus,
      },
    })
  }

  if (verificationStatus === 'approved') {
    isVerification.value = false
    handleSubmit()
  }
}

io.on('userVerificationStatus', (verificationStatusData) => {
  handleRedirectToVerification(verificationStatusData.status)
})

const { favoriteNewDepositBonus } = storeToRefs(useDepositBonusesStore())
</script>

<style scoped>
.first-step {
  position: relative;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;

  height: 100%;
  height: 730px;
  padding-top: var(--spacing-300);
}

.content {
  overflow: hidden auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacing-200);
  justify-content: flex-start;

  height: 100%;
}

.selects-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
  margin: var(--spacing-050) 0 var(--spacing-100);
}

.amount-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);
}

.amount-input-select {
  margin-top: var(--spacing-100);
}

.currency {
  color: var(--text-tertiary);
}

h2 {
  margin: var(--spacing-100) 0 0;
  font: var(--desktop-text-2xl-semibold);
}

.top-info {
  display: flex;
  gap: var(--spacing-300);
  margin-top: var(--spacing-200);
}

.info-block {
  display: flex;
  gap: var(--spacing-100);
  align-items: center;

  .text {
    font: var(--desktop-text-sm-medium);
    color: var(--text-secondary);
  }

  .image {
    height: 28px;
  }
}

.disclaimer {
  display: flex;
  flex-grow: 1;
  align-items: flex-end;

  margin-top: var(--spacing-100);
  margin-bottom: var(--spacing-050);

  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);
  text-align: center;

  p {
    margin: 0;
    padding: 0;
  }

  span {
    color: var(--text-primary);
  }
}

.disclaimer-title {
  color: var(--text-primary);
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.buy-button {
  width: 100%;
}

/* stylelint-disable */
.prepare-verification {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-250);
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  text-align: center;

  .spinner {
    color: var(--icon-primary);
  }

  .text-content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-050);
    align-items: center;
  }

  h2 {
    margin: 0;
    padding: 0;
    font: var(--desktop-text-xl-semibold);
  }

  span {
    font: var(--desktop-text-md-medium);
    color: var(--text-tertiary);
  }
}
/* stylelint-enable */

.buy-crypto-tooltip-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: var(--spacing-300) 0 0;
}

.first-step.mobile {
  height: 100%;
  min-height: auto;
  padding-top: 0;

  h2 {
    margin: 0;
    font: var(--mobile-title-1-semibold);
  }

  .content {
    overflow: hidden;
    overflow-y: auto;
    gap: var(--spacing-150);
    padding: var(--spacing-200) var(--spacing-200) 0;
  }

  .button-wrapper {
    padding: var(--spacing-050) var(--spacing-200) var(--spacing-200);
  }

  .amount-buttons {
    margin-top: calc(-1 * var(--spacing-100));
  }

  .disclaimer {
    margin-bottom: 0;
    padding-bottom: 0;
    font: var(--mobile-caption-1-regular);
  }

  .top-info {
    gap: var(--spacing-125);
    margin-top: 0;
    padding-bottom: 0;
    border-bottom: none;
  }

  .selects-wrapper {
    margin-top: 0;
  }

  .info-block {
    .text {
      font: var(--mobile-caption-1-regular);
    }
  }

  .buy-crypto-tooltip-wrapper {
    margin: var(--spacing-200) 0 0;
  }
}
</style>
