<template>
  <div :class="wrapperClasses" class="payment-methods">
    <h2>
      {{ t('payments.buyCrypto.choosePaymentMethod') }}
      <StTooltip placement="top" disable-teleport>
        <template #activator
          ><StIcon name="circle-info-solid" class="tooltip" size="20" />
        </template>
        <div v-if="!isDisabled" class="tooltip-content">
          {{ t('payments.buyCrypto.tooltipContent') }}
        </div>
      </StTooltip>
    </h2>
    <div class="methods-wrapper" :class="methodsWrapperClasses">
      <StRadioGroup v-model="selectedMethodValue">
        <div
          v-for="method in methods"
          :key="method.methodCode"
          class="payment-method"
          @click="updateSelectedMethod(method.fixedRateId)"
        >
          <div class="content">
            <StRadio :model-data="method.fixedRateId" />
            <div class="method-type">
              <img
                v-if="imagesMap[method.methodCode]"
                :src="imagesMap[method.methodCode]"
                class="method-image"
              />
              <div v-else class="wallet-icon">
                <StIcon
                  name="wallet-solid"
                  :size="platform === 'desktop' ? 20 : 18"
                />
              </div>
              <div class="method-name">
                {{ method.methodName }}
              </div>
            </div>
          </div>
          <div class="left-side">
            <div class="text">
              {{
                isDisabled
                  ? t('payments.buyCrypto.limits')
                  : t('payments.buyCrypto.inputTitle2')
              }}
            </div>
            <div class="amount">
              {{ isDisabled ? getLimits(method) : getRecieveAmount(method) }}
              <StIcon
                :name="iconName"
                :size="platform === 'desktop' ? 16 : 12"
                class="icon-name"
              />
            </div>
          </div>
        </div>
      </StRadioGroup>
    </div>
  </div>
</template>

<script setup lang="ts">
import Decimal from '@st/decimal'
import { isValidNumber } from '@st/utils'
import { useCurrenciesStore } from '../../../stores/useCurrenciesStore'
import { useLimits } from './useLimits'

import BANK_TRANSFER from '../assets/methods/Bank_transfer.png'
import PIX from '../assets/methods/Pix.png'
import GCASH from '../assets/methods/GCash.png'
import MAYA from '../assets/methods/Maya.png'
import CARDS from '../assets/methods/Bank_card.png'
import LOCAL_EFTS from '../assets/methods/Local_EFTS.png'
import WEBPAY from '../assets/methods/WebPay.png'
import HITES from '../assets/methods/Hites.png'
import CHEK from '../assets/methods/Chek.png'
import MACH from '../assets/methods/Mach.png'
import TODITO from '../assets/methods/Todito.png'
import MOBILE_MONEY from '../assets/methods/Mobile_money.png'
import PROMPT_PAY from '../assets/methods/PromtPay.png'
import TRUE_MONEY from '../assets/methods/TrueMoney.png'
import MPAY from '../assets/methods/MPAY.png'
import ECOM from '../assets/methods/ECOM.png'
import P2P from '../assets/methods/P2P.png'
import M10 from '../assets/methods/M10.png'
import POLI from '../assets/methods/POLI.png'
import TOUCH_NGO from '../assets/methods/Touch_nGo.png'
import GRAB_PAY from '../assets/methods/Grab_Pay.png'
import BOOST from '../assets/methods/Boost.png'
import SHOPEE_PAY from '../assets/methods/Shopee_Pay.png'
import FPX from '../assets/methods/FPX.png'
import DUITNOW from '../assets/methods/Duit_Now.png'
import BIOPAGO from '../assets/methods/Biopago.png'
import PAGO_MOVIL from '../assets/methods/Pago_Movil.png'
import QRIS from '../assets/methods/QRIS.png'
import VIRTUAL_ACCOUNT from '../assets/methods/Virtual_account.png'

const imagesMap: Record<string, string> = {
  BANK_TRANSFER,
  PIX,
  GCASH,
  MAYA,
  CARDS,
  LOCAL_EFTS,
  WEBPAY,
  HITES,
  CHEK,
  MACH,
  TODITO,
  MOBILE_MONEY,
  PROMPT_PAY,
  TRUE_MONEY,
  MPAY,
  ECOM,
  P2P,
  M10,
  POLI,
  TOUCH_NGO,
  GRAB_PAY,
  BOOST,
  SHOPEE_PAY,
  FPX,
  DUITNOW,
  BIOPAGO,
  PAGO_MOVIL,
  QRIS,
  VIRTUAL_ACCOUNT,
}

interface Method {
  methodName: string
  methodCode: string
  fixedRateId: string
  fixedRate: number
  expiredFixedRateDate: string
  minLimit?: number
  maxLimit?: number
}

const emit = defineEmits<{
  (e: 'paymentMethodUpdate', value: string): void
}>()

const props = defineProps<{
  platform: 'desktop' | 'mobile'
  selectedMethod: string
  methods: Method[]
  amount: string
  selectedCurrency: number
  isDisabled: boolean
}>()

const { t } = useI18n()

const { format: formatCrypto } = useCryptoFormatter()

const isValidPropsAmount = computed(() => isValidNumber(props.amount))

function getRecieveAmount(method: Method) {
  if (props.isDisabled || !isValidPropsAmount.value) return ''

  return formatCrypto(
    new Decimal(props.amount).div(method.fixedRate).toString(),
  )
}

function getLimits(method: Method) {
  const limits = useLimits({
    selectedMethod: method,
    currencyId: props.selectedCurrency,
  })
  const { minLimit, maxLimit } = limits.value

  const min = formatCrypto(minLimit)
  const max = formatCrypto(maxLimit)

  return `${min}-${max}`
}

const wrapperClasses = computed(() => ({
  mobile: props.platform === 'mobile',
}))

const methodsWrapperClasses = computed(() => ({
  'is-disabled': props.isDisabled,
}))

const { currencies } = useCurrenciesStore()

const iconName = computed(() => currencies[props.selectedCurrency].icon)
const selectedMethodValue = ref(props.selectedMethod || '1')

watch(props, () => {
  selectedMethodValue.value = props.selectedMethod
})

watch(selectedMethodValue, (newValue) => {
  emit('paymentMethodUpdate', newValue)
})

function updateSelectedMethod(code: string) {
  if (props.isDisabled) return

  selectedMethodValue.value = code
}
</script>

<style scoped>
.payment-methods {
  display: flex;
  flex-direction: column;

  .payment-method {
    cursor: pointer;

    display: flex;
    gap: var(--spacing-100);
    align-items: center;

    padding: var(--spacing-200);

    background-color: var(--background-primary);
  }

  :first-child.payment-method {
    border-top-left-radius: var(--border-radius-150, 12px);
    border-top-right-radius: var(--border-radius-150, 12px);
  }

  :last-child.payment-method {
    border-bottom-right-radius: var(--border-radius-150, 12px);
    border-bottom-left-radius: var(--border-radius-150, 12px);
  }
}

.methods-wrapper {
  &.is-disabled {
    opacity: 0.3;
  }
}

h2 {
  display: flex;
  gap: var(--spacing-075);
  align-items: center;

  margin: 0 0 var(--spacing-100);
  padding: 0;

  font: var(--desktop-text-lg-semibold);
}

.tooltip {
  cursor: pointer;
  color: var(--text-secondary, rgba(255 255 255 64%));
}

.tooltip-content {
  width: 240px;
  padding: var(--spacing-100);
  font: var(--desktop-text-md-medium);
}

.wallet-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  width: var(--spacing-400);
  height: var(--spacing-400);

  background: linear-gradient(180deg, #5f91f3 0%, #3e7bf1 100%);
  border-radius: var(--border-radius-full);
}

.content {
  display: flex;
  flex-grow: 1;
  gap: var(--spacing-200);
  align-items: center;
}

.method-type {
  display: flex;
  gap: var(--spacing-150);
  align-items: center;
}

.method-name {
  font: var(--desktop-text-md-semibold);
}

.method-image {
  height: var(--spacing-400);
}

.left-side {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  align-items: flex-end;
}

.text {
  font: var(--desktop-text-sm-medium);
  color: var(--text-tertiary);
}

.amount {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;
  font: var(--desktop-text-sm-semibold);
}

.icon-name {
  line-height: 0;
}

.mobile.payment-methods {
  h2 {
    font: var(--mobile-headline-semibold);
  }

  .payment-method {
    padding: var(--spacing-125) var(--spacing-150);
  }

  .wallet-icon {
    width: var(--spacing-400);
    height: var(--spacing-400);
  }

  .tooltip-content {
    font: var(--mobile-caption-1-regular);
  }

  .content {
    gap: var(--spacing-150);
  }

  .method-type {
    gap: var(--spacing-125);
  }

  .method-name {
    font: var(--mobile-text-medium);
  }

  .left-side {
    .text {
      font: var(--mobile-caption-1-regular);
    }

    .amount {
      font: var(--mobile-caption-1-medium);
    }
  }
}
</style>
